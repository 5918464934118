import React from "react";
import { useQuery } from "react-query";
import CommunitySkeleton from "skeletons/CommunitySkeleton";
import { client } from "utils/api-client";
import Thread from "./Thread";

const PinnedThreads = () => {
  const {
    data: threads,
    isLoading,
    isError,
    error,
  } = useQuery(["PinnedThreads"], () =>
    client.get(`/community/pinned_threads`).then((res) => res.data)
  );

  if (isLoading || !threads || isError) return <CommunitySkeleton />;

  return (
    <div className="threads">
      {threads?.length > 0 ? (
        threads?.map((thread) => (
          <Thread key={thread.id} thread={{ ...thread, isPinned: true }} />
        ))
      ) : (
        <div className="no-threads">No threads found</div>
      )}
    </div>
  );
};

export default PinnedThreads;
