import React from "react";
import Wrapper from "../styles/MobileNavbar";
import { NavLink } from "react-router-dom";
import {
  HistoryIcon,
  HomeIcon,
  SubIcon,
  TrendingIcon,
  LikeIcon,
} from "./Icons";
import { SiYoutubeshorts } from "react-icons/si";
import { HiUsers } from "react-icons/hi";

function MobileNavbar() {
  return (
    <Wrapper>
      <div className="icons">
        <NavLink exact to="/" activeClassName="active">
          <HomeIcon />
        </NavLink>

        <NavLink to="/shorts" activeClassName="active">
          <div style={{ height: "30px", width: "30px" }}>
            <SiYoutubeshorts
              style={{ width: "25px", height: "25px" }}
              fill="#AAAAAA"
            />
          </div>
        </NavLink>

        <NavLink to="/feed/trending" activeClassName="active">
          <TrendingIcon />
        </NavLink>

        {/* <NavLink to="/feed/subscriptions" activeClassName="active">
          <SubIcon />
        </NavLink> */}
        <NavLink to="/community" activeClassName="active">
          <div style={{ height: "30px", width: "30px" }}>
            <HiUsers style={{ width: "25px", height: "25px" }} fill="#AAAAAA" />
          </div>
        </NavLink>

        {/* <NavLink to="/feed/history" activeClassName="active">
          <HistoryIcon />
        </NavLink> */}

        <NavLink to="/feed/liked_videos" activeClassName="active">
          <LikeIcon />
        </NavLink>
      </div>
    </Wrapper>
  );
}

export default MobileNavbar;
