import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { client } from "utils/api-client";
import {
  PageContainer,
  Title,
  CardsContainer,
  Card,
  PlanTitle,
  Price,
  ServicesList,
  ServiceItem,
  SubscribeButton,
} from "../components/Plans";
import { useSubscribePlan } from "hooks/use-subscribe-plan";
import { useUnsubscribePlan } from "hooks/use-unsubscribe-plan";
import AddNewCardModal from "components/AddNewCardModal";
import { useAuth } from "context/auth-context";
import { useBuyCredits } from "hooks/use-buy-credits";
import { useSnackbar } from "react-simple-snackbar";
import PlansConfirmationModal from "components/PlansConfirmationModal";
const SubscriptionPlans = () => {
  const [openSnackbar] = useSnackbar();
  const user = useAuth();
  const { data, isSuccess, isLoading, isError, error, refetch } = useQuery(
    ["Plans"],
    () => client.get("/payments/getPlans").then((res) => res.data),
    { refetchOnWindowFocus: true }
  );

  const [plans, setPlans] = useState(data || []);
  const [priceId, setPriceId] = useState(null);
  const [addCard, setAddCard] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationFor, setConfirmationFor] = useState({
    id: null,
    type: null,
    role: null,
  });

  useEffect(() => {
    if (data) setPlans(data);
  }, [data]);

  const { loading, error: stripeError, subscribe } = useSubscribePlan();
  const { loading: buyLoading, error: buyError, buyCredits } = useBuyCredits();

  const {
    loading: unsubLoading,
    error: unsubError,
    unsubscribe,
  } = useUnsubscribePlan();

  const handleSubscribe = async (priceId, type) => {
    setConfirmationModal(false);
    if (type === "one_time") {
      await handleBuy(priceId);
    } else {
      setPriceId(priceId);
      const res = await subscribe(priceId);
      if (res.success) {
        const newPlans = plans.map((plan) => {
          return { ...plan, isCurrent: priceId === plan.price.id };
        });
        setPlans(newPlans);
      }
    }
  };

  // useEffect(() => {
  //   if (stripeError && stripeError.status === 403) {
  //     setAddCard(true);
  //   }
  // }, [stripeError]);

  // useEffect(() => {
  //   if (buyError && buyError.status === 403) {
  //     setAddCard(true);
  //   }
  // }, [buyError]);

  // const handleFree = async () => {
  //   const res = await unsubscribe();
  //   if (res.success) {
  //     const newPlans = plans.map((plan) => {
  //       return { ...plan, isCurrent: false };
  //     });
  //     setPlans(newPlans);
  //   }
  // };
  const handleBuy = async (priceId) => {
    const res = await buyCredits(priceId);
    if (res.success) {
      openSnackbar("100 Credits added to your account");
    }
  };

  const handleCloseModal = (shouldSubscribe) => {
    setAddCard(false);
    if (shouldSubscribe) {
      if (stripeError) {
        handleSubscribe(priceId);
      } else if (buyError) {
        handleBuy();
      }
    }
  };

  const handleModal = (id, type, role) => {
    setConfirmationFor({ id, type, role });
    setConfirmationModal(true);
  };

  return (
    <PageContainer>
      {addCard && <AddNewCardModal closeModal={handleCloseModal} />}
      {confirmationModal && (
        <PlansConfirmationModal
          closeModal={() => setConfirmationModal(false)}
          handleContinue={() =>
            handleSubscribe(confirmationFor.id, confirmationFor.type)
          }
          type={confirmationFor.role}
        />
      )}
      <Title>Choose Your Plan</Title>
      <CardsContainer>
        {isLoading ? (
          <p>Loading...</p>
        ) : isError ? (
          <p>Error while fetching plans</p>
        ) : (
          <>
            {plans?.length > 0 &&
              plans?.map((plan, index) => (
                <Card key={index}>
                  <div>
                    <div>
                      <PlanTitle>{plan.name}</PlanTitle>
                      <Price>
                        ${(plan.price?.price / 100).toFixed(2)}/month
                      </Price>
                    </div>
                    <div style={{ flexBasis: 1 }}>
                      {plan.role === "BASIC" && (
                        <ServicesList>
                          <ServiceItem>
                            <b>Monthly Credits</b>: 400 credits <br />
                            <span>
                              Equivalent to <b>20 videos of 10 seconds each </b>
                              (20 credits per video) .
                            </span>
                          </ServiceItem>
                          <ServiceItem>
                            <b>Video Quality</b>: 720p resolution
                          </ServiceItem>
                          <ServiceItem>
                            <b>Support</b>: Email support
                          </ServiceItem>
                        </ServicesList>
                      )}
                      {plan.role === "PRO" && (
                        <ServicesList>
                          <ServiceItem>
                            <b>Monthly Credits</b>: 1000 credits <br />
                            <ServicesList className="m-0">
                              <ServiceItem>
                                <span>
                                  Equivalent to{" "}
                                  <b>50 videos of 10 seconds each </b>
                                  (20 credits per video) - 720p
                                </span>
                              </ServiceItem>
                              <div
                                className="text-center m-0"
                                style={{ fontSize: "0.8rem" }}
                              >
                                OR
                              </div>
                              <ServiceItem>
                                <span>
                                  Equivalent to{" "}
                                  <b>25 videos of 10 seconds each </b>
                                  (40 credits per video) - 1080p
                                </span>
                              </ServiceItem>
                            </ServicesList>
                          </ServiceItem>
                          <ServiceItem>
                            <b>Support</b>: Email support
                          </ServiceItem>
                          {/* <ServiceItem>
                            <b>Extra Perks</b>: <br />
                            <ServicesList className="m-0">
                              <ServiceItem>
                                <span>
                                  Direct export to social media platforms
                                </span>
                              </ServiceItem>
                              <ServiceItem>
                                <span>Priority email support</span>
                              </ServiceItem>
                            </ServicesList>
                          </ServiceItem> */}
                        </ServicesList>
                      )}
                      {plan.price.type === "one_time" && (
                        <ServicesList>
                          <ServiceItem>
                            <b>Credits</b>: 100 credits <br />
                            <ServicesList className="m-0">
                              <ServiceItem>
                                <span>
                                  Equivalent to{" "}
                                  <b>5 videos of 10 seconds each </b>
                                  (20 credits per video)
                                </span>
                              </ServiceItem>
                            </ServicesList>
                          </ServiceItem>
                          <ServiceItem>
                            <b>Video Quality</b>: 720p resolution
                          </ServiceItem>
                        </ServicesList>
                      )}
                    </div>
                  </div>
                  <SubscribeButton
                    disabled={
                      plan.isCurrent || (loading && priceId === plan.price?.id)
                    }
                    onClick={() =>
                      handleModal(plan.price?.id, plan.price?.type, plan.role)
                    }
                  >
                    {plan.price.type === "one_time"
                      ? buyLoading
                        ? "Buying..."
                        : "Buy Now"
                      : plan.isCurrent
                      ? "Subscribed"
                      : loading && priceId === plan.price?.id
                      ? "Subscribing..."
                      : "Subscribe"}
                  </SubscribeButton>
                </Card>
              ))}
            {/* <Card>
              <div>
                <div>
                  <PlanTitle>Pay-Per-Use Plan</PlanTitle>
                  <Price>$3 (One-Time Payment)</Price>
                </div>
                <ServicesList>
                  <ServiceItem>
                    <b>Credits</b>: 100 credits <br />
                    <ServicesList className="m-0">
                      <ServiceItem>
                        <span>
                          Equivalent to <b>5 videos of 10 seconds each </b>
                          (20 credits per video)
                        </span>
                      </ServiceItem>
                    </ServicesList>
                  </ServiceItem>
                  <ServiceItem>
                    <b>Video Quality</b>: 720p resolution
                  </ServiceItem>
                </ServicesList>
              </div>

              <SubscribeButton onClick={handleBuy} disabled={buyLoading}>
                {buyLoading ? "Buying..." : "Buy Now"}
              </SubscribeButton>
            </Card> */}
          </>
        )}
      </CardsContainer>
    </PageContainer>
  );
};

export default SubscriptionPlans;
