import React from "react";
import Wrapper from "../styles/Sidebar";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import {
  HistoryIcon,
  HomeIcon,
  LibIcon,
  LikeIcon,
  MoodIcon,
  SubIcon,
  TrendingIcon,
  VidIcon,
} from "./Icons";
import SidebarAuth from "./SidebarAuth";
import { useAuth } from "../context/auth-context";
import Subscriptions from "./Subscriptions";
import { categories } from "config/config";
import { SiYoutubeshorts } from "react-icons/si";
import { HiUsers } from "react-icons/hi";

function Sidebar({ isSidebarOpen, closeSidebar }) {
  const user = useAuth();
  const history = useHistory();
  const location = useLocation();

  const handleCategoryChange = (category) => {
    const searchParams = new URLSearchParams(location.search);
    if (category === "All") {
      searchParams.delete("category");
    } else {
      searchParams.set("category", category);
    }

    // Update the current path with the new query params
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    closeSidebar();
  };
  return (
    <Wrapper open={isSidebarOpen}>
      <NavLink exact to="/" activeClassName="active">
        <div
          className="icon"
          style={{
            marginTop: 10,
          }}
        >
          <HomeIcon />
          <span>Home</span>
        </div>
      </NavLink>

      <NavLink to="/shorts" activeClassName="active">
        <div className="icon">
          <div style={{ height: "30px", width: "30px" }}>
            <SiYoutubeshorts
              style={{ width: "25px", height: "25px" }}
              fill="#AAAAAA"
            />
          </div>
          <span>Shorts</span>
        </div>
      </NavLink>

      <NavLink to="/feed/trending" activeClassName="active">
        <div className="icon">
          <TrendingIcon />
          <span>Trending</span>
        </div>
      </NavLink>

      <NavLink to="/community" activeClassName="active">
        <div className="icon">
          <div style={{ height: "30px", width: "30px" }}>
            <HiUsers style={{ width: "25px", height: "25px" }} fill="#AAAAAA" />
          </div>
          <span>Community</span>
        </div>
      </NavLink>

      <NavLink to="/feed/subscriptions" activeClassName="active">
        <div className="icon">
          <SubIcon />
          <span>Creators you follow</span>
        </div>
      </NavLink>

      <div className="divider"></div>

      <NavLink to="/feed/library" activeClassName="active">
        <div className="icon">
          <LibIcon />
          <span>Library</span>
        </div>
      </NavLink>

      <NavLink to="/feed/history" activeClassName="active">
        <div className="icon">
          <HistoryIcon />
          <span>History</span>
        </div>
      </NavLink>

      <NavLink to="/feed/my_videos" activeClassName="active">
        <div className="icon">
          <VidIcon />
          <span>Your videos</span>
        </div>
      </NavLink>

      <NavLink to="/feed/liked_videos" activeClassName="active">
        <div className="icon">
          <LikeIcon />
          <span>Liked videos</span>
        </div>
      </NavLink>

      <div style={{ margin: "16px" }}>
        <label
          htmlFor="category-select"
          style={{ display: "flex", gap: 5, alignItems: "center" }}
        >
          <MoodIcon />
          <span>Filter by Mood</span>
        </label>
        <select
          className="select-drop"
          id="category-select"
          onChange={(e) => handleCategoryChange(e.target.value)}
          style={{
            width: "100%",
            padding: "8px",
          }}
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      <div className="divider"></div>

      <div style={{ marginBottom: 10 }}>
        {user ? <Subscriptions user={user} /> : <SidebarAuth />}
      </div>
      <div className="divider"></div>

      <NavLink to="/feed/about" activeClassName="">
        <div className="icon">
          <span>About</span>
        </div>
      </NavLink>
      <NavLink to="/feed/contact" activeClassName="">
        <div className="icon">
          <span>Contact us</span>
        </div>
      </NavLink>
      <NavLink to="/feed/how_works" activeClassName="">
        <div className="icon">
          <span>How AITube works</span>
        </div>
      </NavLink>

      <div className="divider"></div>

      <div className="icon terms">
        <NavLink to="/feed/terms_service" activeClassName="">
          <span>Terms</span>
        </NavLink>
        <span>.</span>
        <NavLink to="/feed/privacy_policy" activeClassName="">
          <span>Privacy</span>
        </NavLink>
      </div>
    </Wrapper>
  );
}

export default Sidebar;
