import styled from "styled-components";

const CommunityThread = styled.div`
  color: white;
  flex: 2;
  border-radius: 12px;
  background-color: #263341;
  padding: 20px;

  .go-back {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #64748b;
    background: #1a2734;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .thread-content {
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .user-info span {
    font-size: 1.3rem;
  }

  .more-btn {
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
  }

  .post-text {
    color: #e2e8f0;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .post-text p {
    color: rgba(254, 254, 254, 0.8);
  }

  .read-more {
    color: #06b6d4;
    cursor: pointer;
  }

  .images {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }

  .image {
    width: 100%;
    height: 200px;
    border-radius: 12px;
    object-fit: cover;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .interactions {
    display: flex;
    gap: 20px;
    color: #64748b;
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .interaction-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .interaction-item.liked {
    color: #ef4444;
  }

  .comments-section {
    margin-top: 20px;
  }

  .comment {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .comment-content {
    flex: 1;
  }

  .comment-header {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 5px;
  }

  .comment-name {
    font-weight: 500;
  }

  .comment-time {
    color: #64748b;
    font-size: 0.9em;
  }

  .comment-input {
    margin-top: 20px;
    padding: 2px 10px;
    background-color: #1a2734;
    border-radius: 22px;
  }

  .input-box {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .attached-images {
    display: flex;
    gap: 10px;
    margin: 10px 0;
    flex-wrap: wrap;
  }

  .img-preview {
    position: relative;
  }

  .img-preview button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
  }

  .attached-images img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 12px;
  }

  .input-field {
    flex: 1;
    background: none;
    border: none;
    color: white;
    padding: 8px;
    &::placeholder {
      color: #64748b;
    }
  }

  .attachment-btn {
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
  }

  @media screen and (max-width: 500px) {
    .images {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default CommunityThread;
