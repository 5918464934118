import { useState } from "react";
import { queryCache } from "react-query";
import { client } from "utils/api-client";
import { uploadMedia } from "utils/upload-media";

export const useCreateThread = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createThread = async (title, description, files) => {
    try {
      const attachments = [];
      setLoading(true);
      setError(null);
      for (const file of files) {
        const type = file.type.split("/")[0];
        const url = await uploadMedia({
          type,
          file,
          preset: "ai-tube",
        });
        attachments.push(url);
      }

      const res = await client.post("/community", {
        title,
        description,
        attachments,
      });
      await queryCache.invalidateQueries("Threads");
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      console.error("Login error:", err.response);
    } finally {
      setLoading(false);
    }
  };

  return { createThread, loading, error };
};
