import styled from "styled-components";

const CreateThreadWrapper = styled.div`
  background-color: #0f172a;
  min-height: 100vh;
  padding: 20px;
  color: white;

  .header {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .post-form {
    background-color: rgba(38, 51, 65, 1);
    border-radius: 12px;
    padding: 20px;
    width: 80%;
  }

  .go-back {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #64748b;
    background: rgba(15, 23, 42, 0.5);
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 14px;
  }

  .form-title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .input-label {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .title-input {
    width: 100%;
    padding: 12px;
    background-color: rgba(15, 23, 42, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: white;
    margin-bottom: 20px;
    font-size: 14px;

    &::placeholder {
      color: #64748b;
    }
  }

  .editor-toolbar {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  .toolbar-btn {
    background: none;
    border: none;
    color: #64748b;
    padding: 6px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 4px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  .editor-area {
    width: 100%;
    min-height: 200px;
    padding: 12px;
    background-color: rgba(15, 23, 42, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    color: white;
    margin-bottom: 20px;
    font-size: 14px;
    resize: none;

    &::placeholder {
      color: #64748b;
    }
  }

  .image-preview {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
  }

  .preview-image {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    object-fit: cover;
  }

  .post-button {
    background-color: #08717c;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 24px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgb(4, 85, 94);
    }
  }

  @media screen and (max-width: 768px) {
    .post-form {
      width: 100%;
      padding: 0.7rem;
    }
  }

  @media screen and (max-width: 500px) {
    .post-form {
      margin-bottom: 50px;
    }
  }
`;

export default CreateThreadWrapper;
