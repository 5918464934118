import React, { useEffect, useState } from "react";
import CommunityPageWrapper from "../styles/Community";
import { Link } from "react-router-dom";
import CommunityHeader from "components/CommunityHeader";
import { useQuery } from "react-query";
import { client } from "utils/api-client";
import CommunityThreads from "components/CommunityThreads";
import MyThreads from "components/MyThreads";
import { useLocation } from "react-router-dom";
import PinnedThreads from "components/PinnedThreads";
import TopicsSkeleton from "skeletons/TopicsSkeleton";

const CommunityPage = () => {
  const { data: trendingTopics, isLoading } = useQuery(["TrendyTopics"], () =>
    client.get(`/community/getTrendingTopics`).then((res) => res.data)
  );
  // const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get("t");
  useEffect(() => {
    if (tab) {
      setActiveTab(parseInt(tab));
    }
  }, [tab]);

  return (
    <CommunityPageWrapper>
      <h1 className="header">Community</h1>
      <CommunityHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className="content">
        {activeTab === 0 && <CommunityThreads />}
        {activeTab === 1 && <MyThreads />}
        {activeTab === 2 && <PinnedThreads />}

        {isLoading ? (
          <TopicsSkeleton />
        ) : (
          <div className="trending">
            <h2 className="trending-header">Trendy Topics</h2>
            {trendingTopics?.map((topic, index) => (
              <div key={index} className="trending-topic">
                <Link to={`/community/${topic.id}`}>{topic.title}</Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </CommunityPageWrapper>
  );
};

export default CommunityPage;
