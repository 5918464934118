import React from "react";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`;

const SkeletonBase = styled.div`
  background: linear-gradient(90deg, #2a2a2a 0%, #3a3a3a 50%, #2a2a2a 100%);
  background-size: 200% 100%;
  animation: ${shimmer} 2s linear infinite;
`;

const TextAreaSkeleton = styled(SkeletonBase)`
  width: 100%;
  height: 120px;
  border-radius: 8px;
`;

const ButtonSkeleton = styled(SkeletonBase)`
  width: 100%;
  height: 48px;
  border-radius: 9999px;
`;

const GenerateSkeleton = () => {
  return (
    <Container>
      <TextAreaSkeleton />
      <ButtonSkeleton />
    </Container>
  );
};

export default GenerateSkeleton;
