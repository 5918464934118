import React, { useState } from "react";

const ExpandableParagraph = ({ text, maxWords = 50, shouldExpand = false }) => {
  const [expanded, setExpanded] = useState(false);

  const words = text.split(" ");
  const isTruncated = words.length > maxWords;
  const displayText = expanded
    ? text
    : words.slice(0, maxWords).join(" ") + (isTruncated ? "..." : "");

  return (
    <div className="thread-content">
      <span dangerouslySetInnerHTML={{ __html: displayText }}></span>
      {isTruncated && (
        <span
          className="read-more"
          onClick={shouldExpand ? () => setExpanded(!expanded) : null}
        >
          {expanded ? "Read Less" : "Read More"}
        </span>
      )}
    </div>
  );
};

export default ExpandableParagraph;
