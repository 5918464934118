import axios from "axios";

export async function uploadMedia({ type, file, preset }) {
  const formData = new FormData();
  formData.append("upload_preset", preset);
  formData.append("file", file);
  formData.append("api_key", "479647144865656");

  const data = await axios
    .post(`https://api.cloudinary.com/v1_1/dktyndov2/${type}/upload`, formData)
    .then((res) => res.data);

  return data.secure_url;
}
