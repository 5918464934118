import React, { useState } from "react";
import { MdOutlineStar } from "react-icons/md";
import ProfileWrapper from "styles/Profile";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import GeneratedVideo from "components/GeneratedVideo";
import { useQuery } from "react-query";
import { client } from "utils/api-client";
import ErrorMessage from "components/ErrorMessage";
import { differenceInDays, format } from "date-fns";
import { useUnsubscribePlan } from "hooks/use-unsubscribe-plan";
import Transactions from "components/Transactions";
import GeneratedVideos from "components/GeneratedVideos";

const Profile = () => {
  const {
    loading: unsubLoading,
    error: unsubError,
    unsubscribe,
  } = useUnsubscribePlan();
  const [activeTab, setActiveTab] = useState("videos");
  const { data, isSuccess, isLoading, isError, error, refetch } = useQuery(
    ["ProfileData"],
    () => client.get("/users/profile").then((res) => res.data),
    { refetchOnWindowFocus: true }
  );

  if (isLoading) return <h1>Loading...</h1>;
  if (isError) return <ErrorMessage error={error} />;

  const handleUnsubscribe = () => {
    unsubscribe().then((res) => refetch());
  };

  return (
    <ProfileWrapper>
      <h3 className="profile-title">Profile</h3>
      <div className="d-flex profile-container align-items-start">
        <div className="profile-plan-info">
          <div className="plan-info">
            <div className="d-flex plan-info-header align-items-center">
              <div className="header-badge">
                <img src={data?.user?.avatar} alt="" />
              </div>
              <div className="plan-name-price">
                <h3 className="plan-name">{data?.user?.username}</h3>
                {data?.user?.plan !== "FREE" &&
                  data?.user?.plan !== "PAY-PER-USE" && (
                    <p className="plan-price">
                      ${data?.planDetails?.plan_amount / 100}/
                      {data?.planDetails?.plan_interval}
                    </p>
                  )}
              </div>
            </div>
            <div className="plan-info-footer">
              <h3 className="info-footer-title">
                {data?.user?.plan || "Not Subscribed"}
              </h3>
              {data?.planDetails?.end_time && (
                <span>
                  (
                  {differenceInDays(
                    new Date(data?.planDetails?.end_time * 1000),
                    new Date(Date.now())
                  )}{" "}
                  Days Remaining)
                </span>
              )}
            </div>
          </div>
          <div className="plan-info-bottom d-flex flex-column">
            {data?.user?.nextPlan && (
              <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
                <p className="plan-info-key">Next Plan</p>
                <h4 className="plan-info-value">
                  {data?.user?.nextPlan || "None"}
                </h4>
              </div>
            )}
            <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
              <p className="plan-info-key">Monthly Credit</p>
              <h4 className="plan-info-value">
                {data?.user?.availableCredits || 0}
              </h4>
            </div>
            <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
              <p className="plan-info-key">Non-Expired Credit</p>
              <h4 className="plan-info-value">
                {data?.user?.purchasedCredits || 0}
              </h4>
            </div>
            {data?.attachedCard?.name && (
              <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
                <p className="plan-info-key">Billing Name</p>
                <h4 className="plan-info-value">{data?.attachedCard?.name}</h4>
              </div>
            )}
            {data?.attachedCard?.last4 && (
              <div className="d-flex plan-key-value justify-content-between align-items-center w-100">
                <p className="plan-info-key">Default Payment Method</p>
                <h4 className="plan-info-value">
                  **** **** **** {data?.attachedCard?.last4}
                </h4>
              </div>
            )}
          </div>
          <div className="cancel-container">
            {data?.user?.plan === "FREE" ||
            data?.user?.plan === "PAY-PER-USE" ? (
              <p className="cancel-text">Not Subscribed</p>
            ) : data?.user?.nextPlan === "FREE" ? (
              <p className="cancel-text">Cancelled</p>
            ) : (
              <p onClick={handleUnsubscribe} className="cancel-text">
                Cancel Plan
              </p>
            )}
          </div>
        </div>
        <div className="profile-plan-info profile-history">
          <h4 className="profile-history-title">
            {activeTab === "videos"
              ? "Generated Videos"
              : "Transaction History"}
          </h4>
          <div className="profile-history-navbar">
            <p
              className={`history-nav-btn ${
                activeTab === "videos" && "active"
              }`}
              onClick={() => setActiveTab("videos")}
            >
              Videos
            </p>
            <p
              className={`history-nav-btn ${
                activeTab === "history" && "active"
              }`}
              onClick={() => setActiveTab("history")}
            >
              Transation History
            </p>
          </div>
          {/* Generated Videos */}
          {activeTab === "videos" && <GeneratedVideos />}

          {/* Transaction History */}
          {activeTab === "history" && <Transactions />}
        </div>
      </div>
    </ProfileWrapper>
  );
};

export default Profile;
