import React from "react";
import Wrapper from "../styles/PlansConfirmationModal";
import Button from "styles/Button";
import { CloseIcon } from "./Icons";

const PlansConfirmationModal = ({ closeModal, handleContinue, type }) => {
  return (
    <Wrapper>
      <div className="wrapper">
        <div className="modal-header">
          <h3>
            <CloseIcon width="30px" fill="white" onClick={closeModal} />
            <span>Are You Sure?</span>
          </h3>
        </div>
        <div className="modal-body">
          {type === "PRO" || type === "BASIC" ? (
            <p>
              You are about to upgrade to the{" "}
              <span className="bold">{type} Plan</span>.
            </p>
          ) : (
            <p>
              You are about to buy <span className="bold">Credits</span>.
            </p>
          )}
          <Button onClick={handleContinue} theme="cyan" className="w-100">
            Continue
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default PlansConfirmationModal;
