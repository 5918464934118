import React from "react";
import styled, { keyframes } from "styled-components";
import TopicsSkeleton from "./TopicsSkeleton";

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const SkeletonWrapper = styled.div`
  display: flex;
  justify-content: between;
  align-items: flex-start;
  width: 100%;
  gap: 3rem;

  @media screen and (max-width: 860px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  max-width: 48rem;
  width: 100%;
  background: #1a1f2e;
  padding: 1rem;
  border-radius: 0.5rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #94a3b8;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
`;

const BackText = styled.div`
  font-size: 0.875rem;
`;

const SkeletonBase = styled.div`
  background: #334155;
  border-radius: 0.25rem;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const PostHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Avatar = styled(SkeletonBase)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
`;

const Username = styled(SkeletonBase)`
  width: 6rem;
  height: 1rem;
  margin-left: 0.75rem;
  flex-grow: 1;
`;

const TextLine = styled(SkeletonBase)`
  height: 1rem;
  margin-bottom: 0.5rem;
  &:last-child {
    width: 75%;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const ImageSkeleton = styled(SkeletonBase)`
  aspect-ratio: 1;
`;

const Metrics = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const MetricSkeleton = styled(SkeletonBase)`
  width: 4rem;
  height: 1rem;
`;

const Comments = styled.div`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CommentItem = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CommentAvatar = styled(SkeletonBase)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;

const CommentContent = styled.div`
  margin-left: 0.75rem;
  flex-grow: 1;
`;

const CommentAuthor = styled(SkeletonBase)`
  width: 6rem;
  height: 0.75rem;
  margin-bottom: 0.5rem;
`;

const CommentText = styled(SkeletonBase)`
  height: 0.75rem;
`;

const CommentInput = styled(SkeletonBase)`
  height: 2.5rem;
  margin-top: 1rem;
  background: #1e293b;
`;

const ThreadSkeleton = () => {
  return (
    <Container>
      <div>
        <PostHeader>
          <Avatar />
          <Username />
        </PostHeader>

        <div>
          <TextLine />
          <TextLine />
        </div>

        <ImageGrid>
          <ImageSkeleton />
          <ImageSkeleton />
        </ImageGrid>

        <Metrics>
          <MetricSkeleton />
          <MetricSkeleton />
        </Metrics>
      </div>

      <Comments>
        {[1, 2].map((item) => (
          <CommentItem key={item}>
            <CommentAvatar />
            <CommentContent>
              <CommentAuthor />
              <CommentText />
            </CommentContent>
          </CommentItem>
        ))}
      </Comments>

      <CommentInput />
    </Container>
  );
};

export default ThreadSkeleton;
