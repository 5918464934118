import React, { useEffect, useRef, useState } from "react";
import CommunityPageWrapper from "../styles/Community";
import {
  ClipIcon,
  CommentIcon,
  HeartIcon,
  SendCommentIcon,
} from "components/Icons";
import CommunityThread from "styles/CommunityThread";
import { MdKeyboardBackspace } from "react-icons/md";
import CommunityHeader from "components/CommunityHeader";
import { BsThreeDots } from "react-icons/bs";
import ExpandableParagraph from "components/ExpandableParagraph";
import { useQuery } from "react-query";
import {
  client,
  commentThread,
  deleteThread,
  likeThread,
  pinThread,
} from "utils/api-client";
import { useParams } from "react-router-dom";
import { formatDistance, set } from "date-fns";
import ThreadSkeleton from "skeletons/ThreadSkeleton";
import { useHistory } from "react-router-dom";
import { useAuth } from "context/auth-context";
import { uploadMedia } from "utils/upload-media";
import { FaPlay } from "react-icons/fa";
import ThreadVideoModal from "components/ThreadVideoModal";
import TopicsSkeleton from "skeletons/TopicsSkeleton";
import { Link } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";

const ThreadDetail = () => {
  const user = useAuth();
  const [openSnackbar] = useSnackbar();
  const fileInputRef = useRef(null);
  const history = useHistory();
  const { id } = useParams();

  const {
    data: trendingTopics,
    isLoading: trendingTopicsLoading,
    error,
  } = useQuery(["TrendyTopics"], () =>
    client.get(`/community/getTrendingTopics`).then((res) => res.data)
  );

  const {
    data: thread,
    isLoading,
    isError,
  } = useQuery(["Thread", id], () =>
    client.get(`/community/${id}`).then((res) => res.data)
  );

  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [comment, setComment] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [files, setFiles] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState(null);

  useEffect(() => {
    if (thread) {
      setLikes(thread?._count?.likes);
      setIsLiked(thread.isLiked);
      setIsPinned(thread.isPinned);
    }
  }, [thread]);

  const handleLike = () => {
    if (!user) return openSnackbar("Please login first.");
    likeThread(id);
    setIsLiked(!isLiked);
    setLikes(isLiked ? likes - 1 : likes + 1);
  };

  const addComment = async (e) => {
    e.preventDefault();
    if (comment === "") return;
    if (!user) return openSnackbar("Please login first.");
    const attachments = [];
    setComment("");
    setImgUrls([]);
    for (const file of files) {
      const url = await uploadMedia({
        type: "image",
        file,
        preset: "ai-tube",
      });
      attachments.push(url);
    }
    setFiles([]);
    commentThread(id, comment, attachments);
  };

  const handleActiveTab = (tab) => {
    history.push(`/community?t=${tab}`);
  };

  const handlePin = () => {
    pinThread(thread.id);
    setIsPinned(!isPinned);
    setShowMenu(false);
  };

  const handleDelete = () => {
    setShowMenu(false);
    deleteThread(thread.id);
    history.push("/community");
  };

  const handleCommentImage = (e) => {
    e.preventDefault();
    const uploadedFiles = e.target.files;
    const urls = Array.from(uploadedFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setFiles([...files, ...Array.from(uploadedFiles)]);
    setImgUrls((prev) => [...prev, ...urls]);
    fileInputRef.current.value = null;
  };

  const removeImage = (indexToRemove) => {
    setFiles((currentImages) =>
      currentImages.filter((_, index) => index !== indexToRemove)
    );
    setImgUrls((currentUrls) =>
      currentUrls.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <CommunityPageWrapper>
      <input
        type="file"
        onChange={handleCommentImage}
        ref={fileInputRef}
        hidden
        accept="image/*"
        multiple
      />
      {selectedUrl && (
        <ThreadVideoModal
          url={selectedUrl}
          closeModal={() => setSelectedUrl(null)}
        />
      )}
      <h1 className="header">Community</h1>
      <CommunityHeader setActiveTab={handleActiveTab} />
      <div className="content">
        {isLoading || !thread || isError ? (
          <ThreadSkeleton />
        ) : (
          <CommunityThread>
            <button className="go-back" onClick={() => window.history.back()}>
              <MdKeyboardBackspace /> Go Back
            </button>

            <div className="thread-content">
              <div className="header">
                <div className="user-info">
                  <div className="avatar">
                    <img src={thread?.user?.avatar} alt="Dark bird" />
                  </div>
                  <span>{thread?.user?.username}</span>
                </div>
                {user && (
                  <div className="position-relative">
                    <button
                      className="more-btn"
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      <BsThreeDots />
                    </button>
                    {showMenu && (
                      <div className="more-options">
                        {user.id == thread.userId ? (
                          <>
                            {/* <button className="more-option">Edit</button> */}
                            <button
                              className="more-option"
                              onClick={handleDelete}
                            >
                              Delete
                            </button>
                          </>
                        ) : (
                          <button className="more-option" onClick={handlePin}>
                            {isPinned ? "Unpin Thread" : "Pin Thread"}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <h3 className="thread-title">{thread?.title}</h3>
              <ExpandableParagraph
                text={thread?.description}
                maxWords={100}
                shouldExpand={true}
              />

              {thread?.attachments?.length > 0 && (
                <div className="images">
                  {thread?.attachments?.map((attachment) => {
                    if (attachment.includes("image")) {
                      return (
                        <img
                          src={attachment}
                          alt="Dark bird"
                          className="image"
                        />
                      );
                    } else {
                      return (
                        <div
                          className="image position-relative pointer"
                          onClick={() => setSelectedUrl(attachment)}
                        >
                          <video className="image">
                            <source src={attachment} type="video/mp4" />
                          </video>
                          <div className="play-icon">
                            <FaPlay size={23} />
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              )}

              <div className="interactions">
                <div className="interaction-items">
                  <div
                    className="interaction-item pointer"
                    onClick={handleLike}
                  >
                    <span>
                      <HeartIcon isLiked={isLiked} />
                    </span>
                    {likes} <span>Likes</span>
                  </div>
                  <div className="interaction-item">
                    <span>
                      <CommentIcon
                        fill="transparent"
                        style={{ marginBottom: "-4px" }}
                      />
                    </span>
                    {thread?._count?.comments} <span>Comments</span>
                  </div>
                </div>
                <span>
                  {formatDistance(new Date(thread?.createdAt), new Date(), {
                    addSuffix: true,
                  })}
                </span>
              </div>

              <div className="comments-section">
                {thread?.comments?.map((comment) => (
                  <div className="comment">
                    <div className="avatar">
                      <img src={comment?.user?.avatar} alt="Avatar" />
                    </div>
                    <div className="comment-content">
                      <div className="comment-header">
                        <span className="comment-name">
                          {comment?.user?.username}
                        </span>
                        <span className="comment-time">
                          {formatDistance(
                            new Date(comment?.createdAt),
                            new Date(),
                            { addSuffix: true }
                          )}
                        </span>
                      </div>
                      <p>{comment?.text}</p>
                      {comment?.attachments?.length > 0 && (
                        <div className="images mt-2">
                          {comment?.attachments?.map((attachment) => (
                            <img
                              src={attachment}
                              alt="Dark bird"
                              className="image"
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}

                <form onSubmit={addComment} className="comment-input">
                  <div className="input-box">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Write your comment..."
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <p
                      className="attachment-btn"
                      onClick={() => fileInputRef.current.click()}
                    >
                      <ClipIcon
                        width="28"
                        height="28"
                        style={{ marginBottom: "-10px" }}
                      />
                    </p>
                    <button className="attachment-btn" type="submit">
                      <SendCommentIcon
                        width="28"
                        height="28"
                        style={{ marginBottom: "-10px" }}
                      />
                    </button>
                  </div>
                  {imgUrls?.length > 0 && (
                    <div className="attached-images">
                      {imgUrls.map((url, index) => (
                        <div className="img-preview">
                          <img src={url} alt="" />
                          <button onClick={() => removeImage(index)}>×</button>
                        </div>
                      ))}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </CommunityThread>
        )}

        {trendingTopicsLoading ? (
          <TopicsSkeleton />
        ) : (
          <div className="trending">
            <h2 className="trending-header">Trendy Topics</h2>
            {trendingTopics?.map((topic, index) => (
              <div key={index} className="trending-topic">
                <Link to={`/community/${topic.id}`}>{topic.title}</Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </CommunityPageWrapper>
  );
};

export default ThreadDetail;
