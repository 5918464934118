import styled from "styled-components";

// Styled components
const EditorWrapper = styled.div`
  background-color: #1b2838;
  border-radius: 8px;
  border: none;
  .quill {
  }

  .ql-toolbar {
    border: none !important;
    border-bottom: 1px solid #2a3f5f !important;
    border-radius: 8px 8px 0 0;
    background-color: #1b2838;

    .ql-stroke {
      stroke: #ffffff !important;
    }

    .ql-active {
      color: #ffffff !important;
    }

    .ql-fill {
      fill: #ffffff !important;
    }

    .ql-picker {
      color: #ffffff !important;
    }

    .ql-picker-options {
      background-color: #1b2838;
      border-color: #2a3f5f;
    }
    .ql-picker-label:hover {
      color: #ffffff !important;
    }

    button:hover,
    button:focus {
      .ql-stroke {
        stroke: #ffffff !important;
      }
      .ql-fill {
        fill: #ffffff !important;
      }
    }
  }

  .ql-container {
    border: none !important;
    background-color: #1b2838;
    border-radius: 0 0 8px 8px;
    font-family: system-ui, -apple-system, sans-serif;
  }

  .ql-editor {
    min-height: 200px;
    color: #ffffff;
    font-size: 16px;

    &::before {
      color: rgb(194, 194, 194);
    }
  }
`;

export const ImagePreviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  // margin-top: 20px;
  padding: 10px;
`;

export const ImagePreview = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }
  }
`;

export default EditorWrapper;
