import { formatDistance } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { client, deleteVideo, updateVideoStatus } from "utils/api-client";
import GenerateVideoModal from "./GenerateVideoModal";

const GeneratedVideo = ({ video }) => {
  const timeoutRef = useRef(null); // Ref to store the timeout ID
  const mountedRef = useRef(true); // Ref to track mounted state
  const [showModal, setShowModal] = useState(false);

  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(video.isGenerating);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [isGenerated, setIsGenerated] = useState(false);
  const [thumbnail, setThumbnail] = useState(video.thumbnail);
  const [statusLoading, setStatusLoading] = useState(false);

  useEffect(() => {
    setLoading(video.isGenerating);
    setThumbnail(video.thumbnail);
    if (video.isGenerating) {
      checkVideoStatus(video.generationId);
    }

    // Cleanup function to handle unmounting
    return () => {
      mountedRef.current = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Clear the timeout on unmount
      }
    };
  }, [video]);

  const checkVideoStatus = async (uuid) => {
    try {
      const response = await client.get(`/video_generation/generated/${uuid}`);
      const { status, progress, url, thumbnail, videoId } = response.data;
      if (!mountedRef.current) return; // Exit if the component is unmounted

      if (status === "success") {
        if (!mountedRef.current) return; // Exit if the component is unmounted
        setThumbnail(thumbnail);
        setLoading(false);
        setIsGenerated(true);
      } else if (status === "failed") {
        setLoading(false);
        localStorage.removeItem("video_uuid");
      } else {
        // Continue polling every 5 seconds
        setGenerationProgress(progress);
        timeoutRef.current = setTimeout(
          () => {
            if (mountedRef.current) {
              checkVideoStatus(uuid);
            }
          },
          progress ? 3000 : 10000
        );
      }
    } catch (err) {
      if (mountedRef.current) {
        setLoading(false);
      }
    }
  };

  const togglePublish = () => {
    if (isGenerated || !video.category) {
      localStorage.setItem("video_uuid", video.generationId);
      setShowModal(true);
    } else {
      setStatusLoading(true);
      updateVideoStatus(
        video.status === "draft" ? "published" : "draft",
        video.id
      ).then((res) => {
        setStatusLoading(false);
        setShowMenu(false);
      });
    }
  };

  const handleDelete = () => {
    deleteVideo(video.id).then((res) => {
      setShowMenu(false);
    });
  };

  return (
    <div className="generated-video">
      <div className="video-left-side">
        {loading ? (
          <div className="video-thumbnail active"></div>
        ) : (
          <img src={thumbnail} className="video-thumbnail" alt="" />
        )}
        <div className="video-info d-flex flex-column justify-content-between">
          <h4 className="video-title">{video.title}</h4>
          <p className="video-description">
            {formatDistance(new Date(video.createdAt), new Date(), {
              addSuffix: true,
            })}
          </p>
          <p className="video-description">{video.description}</p>
        </div>
      </div>
      <div className="right-video-status d-flex align-items-center position-relative">
        {loading ? (
          <p className="video-status active">
            Generating: {((generationProgress || 0) * 100).toFixed(2)}%
          </p>
        ) : isGenerated ? (
          <p className="video-status active">Generated</p>
        ) : (
          <p className="video-status active">{video.status}</p>
        )}
        {!loading && (
          <HiOutlineDotsHorizontal onClick={() => setShowMenu(!showMenu)} />
        )}
        {showMenu && (
          <div className="video-menu">
            <button disabled={statusLoading} onClick={togglePublish}>
              {statusLoading
                ? "Updating..."
                : video.status === "draft"
                ? "Publish"
                : "Unpublish"}
            </button>
            <button onClick={handleDelete} className="text-danger">
              Delete
            </button>
          </div>
        )}
      </div>
      {showModal && (
        <GenerateVideoModal closeModal={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default GeneratedVideo;
