import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { queryCache } from "react-query";
import { client } from "utils/api-client";
export const useBuyCredits = () => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const buyCredits = async (priceId) => {
    try {
      setLoading(true);
      setError(null);

      const { data: session } = await client.post("/payments/pay-per-use", {
        priceId,
      });

      await stripe.redirectToCheckout({
        sessionId: session,
      });

      console.log("Subscription created or updated successfully");
      return { success: true };
    } catch (err) {
      setError(err?.response || "An error occurred");
      console.error("Plan Subscription error:", err.response);
      return { success: false };
    } finally {
      setLoading(false);
    }
  };

  return { buyCredits, loading, error };
};
