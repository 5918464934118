import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import ErrorMessage from "../components/ErrorMessage";
import VideoCard from "../components/VideoCard";
import HomeSkeleton from "../skeletons/HomeSkeleton";
import Wrapper from "../styles/Home";
import VideoGrid from "../styles/VideoGrid";
import { client } from "../utils/api-client";

const LIMIT = 20;
function Home() {
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [videos, setVideos] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const category = searchParams.get("category") || "All";
  const loadingRef = useRef(null);

  const { data, isSuccess, isLoading, isError, error } = useQuery(
    ["Home", category, page],
    () => {
      const categoryQuery =
        category !== "All"
          ? `?category=${category}&pageSize=${LIMIT}&page=${page}`
          : `?pageSize=${LIMIT}&page=${page}`;
      return client
        .get(`/videos${categoryQuery}`)
        .then((res) => res.data.videos);
    },
    { refetchOnWindowFocus: true }
  );

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    setVideos([]);
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && videos.length / LIMIT < page) {
      const newArr = [...videos, ...data];
      setVideos(newArr);
      loadingRef.current = false;
    }
  }, [data]);

  const handleScroll = () => {
    if (
      data?.length <= 0 ||
      window.innerHeight + document.documentElement.scrollTop <
        document.documentElement.offsetHeight - 100 ||
      loadingRef.current
    ) {
      return;
    }
    loadingRef.current = true;
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isLoading && videos.length === 0) return <HomeSkeleton />;
  if (isError) return <ErrorMessage error={error} />;

  return (
    <Wrapper>
      <VideoGrid>
        {videos.length > 0
          ? videos.map((video) => <VideoCard key={video.id} video={video} />)
          : null}
      </VideoGrid>
    </Wrapper>
  );
}

export default Home;
