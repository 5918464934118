import React, { useEffect, useRef, useState } from "react";
import Wrapper from "../styles/Navbar";
import GoogleAuth from "./GoogleAuth";
import { NavLink } from "react-router-dom";
import { GenerateVideoIcon, HamburgerIcon } from "./Icons";
import Search from "./Search";
import { useAuth } from "../context/auth-context";
import UserDropdown from "./UserDropdown";
import UploadVideo from "./UploadVideo";
import SignIn from "./SignIn";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import GenerateVideo from "./GenerateVideo";
import LoginModal from "./LoginModal";

function Navbar({ toggleSidebarOpen }) {
  const user = useAuth();
  const uploadRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    if (uploadRef.current) {
      tippy(uploadRef.current, {
        content: "Upload Your AI-generated Video",
        placement: "bottom",
      });
    }
  }, []);
  return (
    <>
      <Wrapper>
        <div className="logo flex-row">
          <HamburgerIcon
            onClick={toggleSidebarOpen}
            className="toggle-navhandler"
          />
          <span>
            <NavLink to="/">
              <img
                src="/logo-aitube.png"
                alt="logo"
                style={{
                  width: 50,
                  height: 50,
                  color: "white",
                }}
              />
            </NavLink>
          </span>
        </div>
        <Search />
        <ul
          className="m-0"
          style={{
            display: "flex",
            gap: "14px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <li>
            {user && (
              <div
                style={{ display: "flex", gap: "14px", alignItems: "center" }}
              >
                <UploadVideo />
                <GenerateVideo />
              </div>
            )}
          </li>
          <li>
            {user ? (
              <UserDropdown user={user} />
            ) : (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <div
                  className="upload-icon m-0 generate-icon"
                  onClick={openDialog}
                >
                  <span>Generate/Upload</span>
                  <GenerateVideoIcon />
                </div>
                <div className="auth-hidden" style={{ maxWidth: "180px" }}>
                  <SignIn openDialog={openDialog} />
                </div>
                {/* <div className="auth-hidden" style={{ maxWidth: "180px" }}>
                  <GoogleAuth />
                </div> */}
              </div>
            )}
          </li>
        </ul>

        {/* Dialog */}
        {isDialogOpen && <LoginModal closeDialog={closeDialog} />}
      </Wrapper>
    </>
  );
}

export default Navbar;
