import React, { useEffect, useRef, useState } from "react";
import { addVideoView } from "utils/api-client";
import videojs from "video.js";
import "video.js/dist/video-js.css";

const ShortVideoPlayer = ({
  previewUrl,
  video,
  onPlayerReady,
  videoView = true,
  handleScrollDown,
  isVisible,
}) => {
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const { id, url, thumbnail } = video;

  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      controls: false, // Disable default controls
      fluid: true,
      playsinline: true,
      autoplay: false,
      muted: true,
      preload: "none",
    });
    vjsPlayer.poster(thumbnail);
    setPlayer(vjsPlayer);
    onPlayerReady(vjsPlayer);

    vjsPlayer.on("ended", () => {
      if (!videoView) return;
      addVideoView(id);
      handleScrollDown();
    });

    return () => {
      if (vjsPlayer) {
        vjsPlayer.dispose();
      }
    };
  }, [id, thumbnail, url, previewUrl]);

  useEffect(() => {
    if (player) {
      if (isVisible) {
        if (!player.src()) {
          if (!previewUrl) {
            const optimizedUrl = `${url}?f_auto&q_auto:good&vc_auto`;
            player.src({ type: "video/mp4", src: optimizedUrl });
          } else {
            player.src({ type: "video/mp4", src: previewUrl });
          }
          player
            .play()
            .catch((error) => console.error("Playback error:", error));
        }
      } else {
        // player.pause();
        // videoRef.current.removeAttribute("src");
      }
    }
  }, [isVisible, player]);

  return (
    <div data-vjs-player style={{ height: "100%", overflow: "hidden" }}>
      <video
        controls
        ref={videoRef}
        className="video-js vjs-big-play-centered"
        style={{ height: "100%", objectFit: "contain" }}
        preload="none"
        fetchPriority="high"
      ></video>
    </div>
  );
};

export default ShortVideoPlayer;
