import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppProviders from "./components/AppProviders";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

ReactDOM.render(
  <AppProviders>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </AppProviders>,
  document.getElementById("root")
);
