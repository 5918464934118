import React, { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import ExpandableParagraph from "./ExpandableParagraph";
import { CommentIcon, HeartIcon } from "./Icons";
import { formatDistance, set } from "date-fns";
import { deleteThread, likeThread, pinThread } from "utils/api-client";
import { useAuth } from "context/auth-context";
import { useSnackbar } from "react-simple-snackbar";

const Thread = ({ thread }) => {
  const user = useAuth();
  const [openSnackbar] = useSnackbar();
  const [likes, setLikes] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  useEffect(() => {
    if (thread) {
      setLikes(thread?._count?.likes);
      setIsLiked(thread.isLiked);
      setIsPinned(thread.isPinned);
    }
  }, [thread]);

  const handleLike = () => {
    if (!user) return openSnackbar("Please login first.");
    likeThread(thread.id);
    setIsLiked(!isLiked);
    setLikes(isLiked ? likes - 1 : likes + 1);
  };

  const handlePin = () => {
    pinThread(thread.id);
    setIsPinned(!isPinned);
    setShowMenu(false);
  };

  const handleDelete = () => {
    setShowMenu(false);
    deleteThread(thread.id);
  };

  return (
    <div className="thread-card">
      <div className="thread-header">
        <div className="user-info">
          <div className="avatar">
            <img src={thread.user?.avatar} alt="" />
          </div>
          <span>{thread.user?.username}</span>
        </div>
        {user && (
          <div className="position-relative">
            <button className="more-btn" onClick={() => setShowMenu(!showMenu)}>
              <BsThreeDots />
            </button>
            {showMenu && (
              <div className="more-options">
                {user.id == thread.userId ? (
                  <>
                    {/* <button className="more-option">Edit</button> */}
                    <button className="more-option" onClick={handleDelete}>
                      Delete
                    </button>
                  </>
                ) : (
                  <button className="more-option" onClick={handlePin}>
                    {isPinned ? "Unpin Thread" : "Pin Thread"}
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Link to={`/community/${thread.id}`}>
        <h3 className="thread-title">{thread.title}</h3>
        <ExpandableParagraph text={thread.description} />
      </Link>
      <div className="interactions">
        <div className="interaction-items">
          <div className="interaction-item pointer" onClick={handleLike}>
            <span>
              <HeartIcon isLiked={isLiked} />
            </span>
            {likes} <span>Likes</span>
          </div>
          <Link to={`/community/${thread.id}`} className="interaction-item">
            <span>
              <CommentIcon style={{ marginBottom: "-4px" }} />
            </span>
            {thread._count?.comments} <span>Comments</span>
          </Link>
        </div>
        <span>
          {formatDistance(new Date(thread.createdAt), new Date(), {
            addSuffix: true,
          })}
        </span>
      </div>
    </div>
  );
};

export default Thread;
