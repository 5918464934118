import React from "react";
import GenerateVideoModal from "./GenerateVideoModal";
import { GenerateVideoIcon } from "./Icons";
import { useAuth } from "context/auth-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function GenerateVideo() {
  const user = useAuth();
  const [showModal, setShowModal] = React.useState(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleGenerateClick = () => {
    // if (user && user.plan != "FREE") {
    setShowModal(true);
    // } else {
    //   history.push("/plans");
    // }
  };

  return (
    <div>
      <div
        className="upload-icon m-0 generate-icon"
        onClick={handleGenerateClick}
      >
        <span>Generate</span>
        <GenerateVideoIcon />
      </div>
      {showModal && <GenerateVideoModal closeModal={closeModal} />}
    </div>
  );
}

export default GenerateVideo;
