import { format } from "date-fns";
import React from "react";
import { useQuery } from "react-query";
import { client } from "utils/api-client";
import ErrorMessage from "./ErrorMessage";

const Transactions = () => {
  const { data, isSuccess, isLoading, isError, error, refetch } = useQuery(
    ["Transactions"],
    () => client.get("/users/getTransactions").then((res) => res.data),
    { refetchOnWindowFocus: true }
  );

  if (isLoading) return <h4>Loading...</h4>;
  if (isError) return <ErrorMessage error={error} />;

  if (data?.length === 0)
    return <h4 className="text-center mt-3">No transactions found</h4>;

  return (
    <div className="generated-videos-container">
      {data?.map((transaction, index) => (
        <div key={index} className="generated-video transaction-history">
          <div className="history-left-side">
            <h3>
              {format(new Date(transaction.createdAt * 1000), "dd MMMM yyyy")}
            </h3>
            <p>xxxx xxxx xxxx {transaction.last4}</p>
            <p className="card-brand">{transaction.brand}</p>
          </div>
          <div className="history-right-side">
            <h3>${transaction.amount / 100}</h3>
            <p>
              Paid For: <b>{transaction?.type}</b>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Transactions;
