import { queryCache } from "react-query";
import axios from "axios";

export const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

export function authenticate(response) {
  client({
    method: "POST",
    url: "/auth/google-login",
    data: { idToken: response.credential },
  })
    .then((response) => {
      const token = response?.data?.token;
      const authUser = response?.data?.user;
      if (token && authUser) {
        localStorage.setItem("token", token);
        localStorage.setItem("authUser", JSON.stringify(authUser));
      }
      window.location.assign(window.location.href);
    })
    .catch((error) => {
      console.log("Sign in error: ", error.response);
    });
}

export async function signoutUser() {
  // await client.get("/auth/signout");
  localStorage.clear();
  window.location.pathname = "/";
}

export async function updateUser(user) {
  await client.put("/users", user);
  await queryCache.invalidateQueries("Channel");
  await queryCache.invalidateQueries("AuthProvider");
}

export async function addVideoView(videoId) {
  await client.get(`/videos/${videoId}/view`);
  await queryCache.invalidateQueries("History");
}

export async function addComment({ video, comment }) {
  await client.post(`/videos/${video.id}/comments`, { text: comment });
  await queryCache.invalidateQueries(["WatchVideo", video.id]);
}

export async function addVideo(video) {
  await client.post("/videos", video);
  await queryCache.invalidateQueries("Channel");
  await queryCache.invalidateQueries("Home");
}
export async function updateVideo(video) {
  await client.put("/videos", video);
  await queryCache.invalidateQueries("Channel");
  await queryCache.invalidateQueries("Home");
  await queryCache.invalidateQueries("GeneratedVideos");
}

export async function updateVideoStatus(status, id) {
  await client.put("/videos/status", { status, id });
  await queryCache.invalidateQueries("Channel");
  await queryCache.invalidateQueries("GeneratedVideos");
}

export async function toggleSubscribeUser(channelId) {
  await client.get(`/users/${channelId}/toggle-subscribe`);
  await queryCache.invalidateQueries("Channel");
  await queryCache.invalidateQueries("Channels");
  await queryCache.invalidateQueries("Subscriptions");
  await queryCache.invalidateQueries("AuthProvider");
  await queryCache.invalidateQueries("WatchVideo");
  await queryCache.invalidateQueries("SearchResults");
}

export async function likeVideo(videoId) {
  await client.get(`/videos/${videoId}/like`);
  await queryCache.invalidateQueries(["WatchVideo", videoId]);
}

export async function dislikeVideo(videoId) {
  await client.get(`/videos/${videoId}/dislike`);
  await queryCache.invalidateQueries(["WatchVideo", videoId]);
}

export async function deleteVideo(videoId) {
  await client.delete(`/videos/${videoId}`);
  await queryCache.invalidateQueries("Channel");
  await queryCache.invalidateQueries("Home");
  await queryCache.invalidateQueries("GeneratedVideos");
}

export async function deleteComment(comment) {
  await client.delete(`/videos/${comment.videoId}/comments/${comment.id}`);
  await queryCache.invalidateQueries("WatchVideo");
}

export async function likeThread(threadId) {
  await client.post(`/community/likes/${threadId}`);
  await queryCache.invalidateQueries(["Thread", threadId]);
}

export async function pinThread(threadId) {
  await client.put(`/community/pin/${threadId}`);
  await queryCache.invalidateQueries(["Thread", threadId]);
  await queryCache.invalidateQueries("PinnedThreads");
}

export async function deleteThread(threadId) {
  await client.delete(`/community/${threadId}`);
  await queryCache.invalidateQueries("Threads");
}

export async function commentThread(threadId, text, attachments) {
  await client.post(`/community/comment/${threadId}`, { text, attachments });
  await queryCache.invalidateQueries(["Thread", threadId]);
}
