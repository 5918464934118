import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { queryCache } from "react-query";
import { client } from "utils/api-client";
export const useSubscribePlan = () => {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const subscribe = async (priceId) => {
    try {
      setLoading(true);
      setError(null);

      const { data: subscription } = await client.post("/payments/subscribe", {
        priceId,
      });
      if (
        subscription.status === "incomplete" ||
        subscription.status === "requires_payment_method"
      ) {
        const { client_secret } = subscription.latest_invoice.payment_intent;
        const { error } = await stripe.confirmCardPayment(client_secret);

        if (error) {
          console.error("Payment confirmation error:", error);
          throw new Error("Payment confirmation failed");
        } else {
          console.log("Payment successful");
          setTimeout(async () => {
            await queryCache.invalidateQueries("AuthProvider");
          }, 4000);
          return { success: true };
        }
      } else {
        if (subscription.session_id) {
          await stripe.redirectToCheckout({
            sessionId: subscription.session_id,
          });
        }
        console.log("Subscription created or updated successfully");
        setTimeout(async () => {
          await queryCache.invalidateQueries("AuthProvider");
        }, 4000);
        return { success: true };
      }
    } catch (err) {
      setError(err?.response || "An error occurred");
      console.error("Plan Subscription error:", err);
      return { success: false };
    } finally {
      setLoading(false);
    }
  };

  return { subscribe, loading, error };
};
