import React from "react";
import Wrapper from "styles/ThreadVideoModal";
import { CloseIcon } from "./Icons";
import VideoPlayer from "./VideoPlayer";

const ThreadVideoModal = ({ closeModal, url }) => {
  return (
    <Wrapper>
      <div className="modal-content">
        <div className="modal-header">
          <div className="modal-header-left">
            <CloseIcon width="30px" onClick={closeModal} />
          </div>
        </div>
        <div className="tab video-preview">
          <VideoPlayer
            previewUrl={url}
            video={{ id: "", url, thumbnail: "" }}
            videoView={false}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ThreadVideoModal;
