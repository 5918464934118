import React from "react";
import GeneratedVideo from "./GeneratedVideo";
import ErrorMessage from "./ErrorMessage";
import { useQuery } from "react-query";
import { client } from "utils/api-client";

const GeneratedVideos = () => {
  const { data, isSuccess, isLoading, isError, error, refetch } = useQuery(
    ["GeneratedVideos"],
    () => client.get("/videos/generatedVideos").then((res) => res.data),
    { refetchOnWindowFocus: true }
  );

  if (isLoading) return <h4>Loading...</h4>;
  if (isError) return <ErrorMessage error={error} />;

  if (data?.length === 0)
    return <h4 className="text-center mt-3">No generated videos found</h4>;

  return (
    <div className="generated-videos-container">
      {data?.map((video, index) => (
        <GeneratedVideo key={index} video={video} />
      ))}
    </div>
  );
};

export default GeneratedVideos;
