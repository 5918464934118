import styled from "styled-components";

const ProfileWrapper = styled.div`
  width: 100%;
  padding: 2rem 1rem;
  .profile-title {
    font-size: 1.4rem;
  }
  .profile-container {
    gap: 20px;
  }
  .profile-plan-info {
    width: 400px;
    background: ${(props) => props.theme.lightNavyBlue};
    border-radius: 15px;
    padding: 1rem;
  }
  .plan-info {
    padding: 1rem;
    background: #08717c;
    border-radius: 15px;
  }
  .plan-info-header {
    gap: 15px;
  }
  .header-badge {
    background: #ffffff;
    border-radius: 50%;
    color: #fff;
    width: 60px;
    height: 60px;
    display: grid;
    place-items: center;
  }
    .header-badge img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
  .plan-name-price {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }
  .plan-name {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
  }
  .plan-price {
    font-size: 0.8rem;
    margin: 0;
    color: #f9f8ff;
  }
  .plan-info-footer {
    border-top: 1px solid rgba(0, 13, 13, 0.2);
    border-opacity: 0.2;
    margin-top: 20px;
    padding-top: 1rem;
    position: relative;
  }
  .info-footer-title {
    margin: 0;
    font-size: 1rem;
    text-align: center;
  }
  .plan-info-footer span {
    position: absolute;
    right: 0.6rem;
    margin: 0;
    bottom: 32%;
    transform: translateY(65%);
    font-size: 0.6rem;
    font-weight: 200;
    color: rgba(255, 255, 255, 0.7);
  }
  .plan-info-bottom {
    gap: 20px;
    padding: 1rem;
  }
  .plan-key-value {
    gap: 10px;
  }
  .plan-info-key {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
    font-weight: 300;
    margin: 0;
    flex-basis: 50%;
  }
  .plan-info-value {
    font-size: 0.9rem;
    font-weight: 500;
    margin: 0;
    flex-basis: 50%;
  }
  .cancel-container {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
    padding-bottom: 0;
    margin-top: 20px;
  }
  .cancel-text {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 300;
    cursor: pointer;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }

  .profile-history {
    flex-grow: 1;
  }
  .profile-history-title {
    font-size: 1.2rem;
  }
  .profile-history-navbar {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    border-opacity: 0.2;
    width: 100%;
    display: flex;
  }
  .history-nav-btn {
    margin: 0;
    padding: 0.7rem 1rem;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  }
  .history-nav-btn.active {
    color: #fff;
    border-bottom: 2px solid #fff;
    font-weight: 500;
  }
  .generated-videos-container {
    max-height: 500px;
    overflow-y: scroll;
    padding-right: 1rem;
  }
  .generated-video {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  .generated-video:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .video-left-side {
    display: flex;
    gap: 10px;
  }
  .video-thumbnail {
    width: 120px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
  background: rgb(218, 218, 218);
  }
  .video-title {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
  }
  .video-description {
    margin: 0;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.6);
  }
  .right-video-status {
    gap: 20px;
  }
  .video-status {
    margin: 0;
    font-weight: 300;
    font-size: 0.8rem;
    text-transform: capitalize;
  }
  .video-status.active {
    color: #00c62b;
  }
  .transaction-history {
    align-items: normal;
  }
  // Transaction History
  .history-left-side {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .transaction-history h3 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
  }
  .transaction-history p {
    font-size: 0.8rem;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
  }
  .history-right-side {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 150px;
  }
  .video-menu {
    position: absolute;
    top: 30px;
    right: 0;
    background: #fff;
    border-radius: 10px;
    padding: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;4
  }
    .video-menu button{
      cursor: pointer;
      margin: 0;
      padding: 0.5rem 2rem;
      color: #000;
      border-radius: 10px;
      text-align: center;
      border: none;
      outline: none;
      width: 100%;
      background: transparent;
    }
    .video-menu button.text-danger{
      cursor: pointer;
      margin: 0;
      padding: 10px 0;
      color: #8d4353;
    }
      .video-menu button:hover{
        background: #f2f2f2;
      }
    .card-brand{
        text-transform: capitalize;
    }
  @media screen and (max-width: 860px) {
    .profile-container {
      flex-direction: column;
    }
    .profile-plan-info {
      width: 100%;
    }
  }

  @media screen and (max-width: 420px) {
    .info-footer-title {
      text-align: start;
    }
      .history-right-side{
        align-items: end;
      }
  }
`;

export default ProfileWrapper;
