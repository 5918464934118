import React from "react";
import Thread from "./Thread";
import { useQuery } from "react-query";
import { client } from "utils/api-client";
import CommunitySkeleton from "skeletons/CommunitySkeleton";

const CommunityThreads = () => {
  const {
    data: threads,
    isLoading,
    isError,
    error,
  } = useQuery(["Threads"], () =>
    client.get(`/community`).then((res) => res.data)
  );

  if (isLoading || !threads || isError) return <CommunitySkeleton />;

  return (
    <div className="threads">
      {threads?.length > 0 ? (
        threads?.map((thread) => <Thread key={thread.id} thread={thread} />)
      ) : (
        <div className="no-threads">No threads found</div>
      )}
    </div>
  );
};

export default CommunityThreads;
