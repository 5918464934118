import styled, { keyframes } from "styled-components";

export const openModal = keyframes`
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background: rgba(0, 0, 0, 0.7);
  animation: ${openModal} 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  .share-modal {
    width: 90%; /* Use percentage for responsiveness */
    max-width: 480px; /* Ensure it doesn't exceed 480px on larger screens */
    border-radius: 4px;
    background: ${(props) => props.theme.lightNavyBlue};
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-header h3 {
    font-size: 20px;
    margin: 0;
  }
  .modal-header svg {
    width: 30px;
    height: 30px;
    fill: #ffffff;
  }
  .socials {
    position: relative;
    max-width: 100%;
    width: 100%;
  }
  .socials-container {
    overflow-x: hidden;
    gap: 20px;
    display: flex;
    scroll-behavior: smooth;
  }
  .socials-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
  .socials-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .social-item {
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .social-item img {
    width: 100%;
    height: 100%;
  }
  .social-navigator {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.7) !important;
    width: 40px;
    height: 40px;
  }
  .social-navigator:hover {
    background: rgba(0, 0, 0, 0.8) !important;
  }
  .social-navigator.right {
    right: -20px;
  }
  .social-navigator.left {
    left: -20px;
  }
  .video-url {
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: black;
    margin-top: 30px;
  }
  .video-url p {
    margin: 0;
    color: #ffffff;
    font-size: 14px;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default Wrapper;
