import React from "react";
import { useAuth } from "context/auth-context";
import { Link } from "react-router-dom";

const CommunityHeader = ({ activeTab, setActiveTab }) => {
  const user = useAuth();

  return (
    <div className="nav">
      <div className="nav-tabs">
        <button
          className={`nav-link ${activeTab === 0 ? "active" : ""}`}
          onClick={() => setActiveTab(0)}
        >
          Recent Threads
        </button>
        {user && (
          <>
            <button
              className={`nav-link ${activeTab === 1 ? "active" : ""}`}
              onClick={() => setActiveTab(1)}
            >
              My Threads
            </button>
            <button
              className={`nav-link ${activeTab === 2 ? "active" : ""}`}
              onClick={() => setActiveTab(2)}
            >
              Pinned
            </button>
          </>
        )}
      </div>
      {user && (
        <Link to="/community/create" className="start-thread">
          Start Thread
        </Link>
      )}
    </div>
  );
};

export default CommunityHeader;
