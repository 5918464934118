import styled from "styled-components";

const MainComponent = styled.div`
  background-color: #0f172a;
  padding: 20px;
  color: white;

  .header {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .nav {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    justify-content: space-between;
  }

  .nav-tabs {
    gap: 20px;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 70%;
  }

  .nav-link {
    color: #64748b;
    padding-bottom: 10px;
    cursor: pointer;
    background: none;
    transition: all 0.3s ease-in-out;
  }

  .nav-link:hover {
    color: white !important;
  }

  .nav-link.active {
    color: white;
    border-bottom: 2px solid #08717c;
  }

  .start-thread {
    background-color: #08717c;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 5px 16px;
    cursor: pointer;
  }

  .content {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }
  .threads {
    flex: 2;
  }
  .thread-card {
    background-color: #263341;
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
  }

  .thread-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #64748b;
  }
  .avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .more-btn {
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
  }

  .thread-content {
    color: #e2e8f0;
    margin-bottom: 15px;
  }

  .read-more {
    color: #08717c;
    cursor: pointer;
  }

  .interactions {
    display: flex;
    justify-content: space-between;
  }
  .interaction-items {
    display: flex;
    gap: 20px;
  }
  .interactions span {
    color: #64748b;
  }

  .interaction-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .trending {
    background-color: #263341;
    border-radius: 12px;
    padding: 20px;
    flex: 1;
  }

  .trending-header {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .trending-topic {
    color: #e2e8f0;
    padding: 10px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
    text-decoration: none;
  }

  .thread-title {
    color: #e2e8f0;
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .thread-content h1 {
    font-size: 1.3rem;
    font-weight: 500;
  }
  .thread-content strong {
    font-weight: 500;
    color: white;
  }
  .thread-content h2 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  .thread-content h2 {
    font-size: 1rem;
    font-weight: 500;
  }

  .thread-content p {
    font-size: 0.9em;
    margin: 0;
    font-weight: 300;
    color: rgb(180, 180, 180);
  }

  .more-options {
    position: absolute;
    right: 0px;
    top: 30px;
    background: #64748b;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    min-width: 150px;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .more-option {
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    padding: 0px 20px;
    margin: 0;
  }
  @media screen and (max-width: 860px) {
    .content {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 500px) {
    .content {
      padding-bottom: 50px;
    }

    .nav {
      flex-direction: column-reverse;
      align-items: start;
      gap: 10px;
      margin-top: 30px;
    }

    .nav-tabs {
      width: 100%;
    }
    .interactions {
      flex-direction: column-reverse;
    }
  }
`;

export default MainComponent;
