import React, { useMemo, useRef, useState } from "react";
import CreateThreadWrapper from "styles/CreateThread";
import TextEditor from "components/TextEditor";
import { MdKeyboardBackspace } from "react-icons/md";
import EditorWrapper, {
  ImagePreview,
  ImagePreviewContainer,
} from "styles/EditorWrapper";
import { useCreateThread } from "hooks/use-create-thread";
import { useSnackbar } from "react-simple-snackbar";

const CreateThread = () => {
  const { createThread, loading } = useCreateThread();
  const [openSnackbar] = useSnackbar();
  const [files, setFiles] = useState([]);
  const [editorContent, setEditorContent] = useState("");
  const [title, setTitle] = useState("");
  const quillRef = useRef(null);

  const images = useMemo(() => {
    return files.map((file) => {
      return { url: URL.createObjectURL(file), type: file.type };
    });
  }, [files]);

  // Cleanup function for object URLs
  React.useEffect(() => {
    return () => {
      images.forEach((url) => {
        if (url.url.startsWith("blob:")) {
          URL.revokeObjectURL(url.url);
        }
      });
    };
  }, [images]);

  const removeImage = (indexToRemove) => {
    setFiles((currentImages) =>
      currentImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handlePost = () => {
    if (!title || !editorContent)
      return openSnackbar("Please fill in all the fields");
    createThread(title, editorContent, files).then((res) => {
      setFiles([]);
      handleReset();
      setTitle("");
    });
  };

  const handleReset = () => {
    setEditorContent(""); // Reset state
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.setContents([]); // Clear the editor's content
    }
  };

  return (
    <CreateThreadWrapper>
      <h1 className="header">Community</h1>

      <div className="post-form">
        <button className="go-back" onClick={() => window.history.back()}>
          <MdKeyboardBackspace /> Go Back
        </button>

        <h2 className="form-title">Ask anything from community</h2>

        <label className="input-label">Title</label>
        <input
          type="text"
          className="title-input"
          placeholder="Title goes here *"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        {/* <div id="editor"></div> */}
        <EditorWrapper>
          <TextEditor
            editorContent={editorContent}
            setEditorContent={setEditorContent}
            setImages={setFiles}
            quillRef={quillRef}
          />
          {images.length > 0 && (
            <ImagePreviewContainer>
              {images.map((image, index) => (
                <ImagePreview key={`image-${index}`}>
                  {image.type.startsWith("image/") ? (
                    <img src={image.url} alt={`Uploaded ${index + 1}`} />
                  ) : (
                    <video src={image.url} controls />
                  )}
                  <button onClick={() => removeImage(index)}>×</button>
                </ImagePreview>
              ))}
            </ImagePreviewContainer>
          )}
        </EditorWrapper>
        <div className="w-100 d-flex justify-content-end mt-3">
          <button
            onClick={handlePost}
            disabled={loading}
            className="post-button"
          >
            {loading ? "Posting..." : "Post Now"}
          </button>
        </div>
      </div>
    </CreateThreadWrapper>
  );
};

export default CreateThread;
