import React from "react";
import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  background: #1e293b;
  border-radius: 0.5rem;
  padding: 1rem;
  width: 100%;
  flex: 1;
`;

const SkeletonBase = styled.div`
  background: #334155;
  border-radius: 0.25rem;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const Header = styled(SkeletonBase)`
  font-weight: 600;
  color: #e2e8f0;
  margin-bottom: 1rem;
  height: 1.25rem;
  width: 8rem;
`;

const TopicsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const TopicItem = styled(SkeletonBase)`
  height: 3rem;
  width: 100%;
  border-radius: 0.375rem;
`;

const TopicsSkeleton = () => {
  return (
    <Container>
      <TopicsList>
        {[1, 2, 3, 4].map((index) => (
          <TopicItem key={index} />
        ))}
      </TopicsList>
    </Container>
  );
};

export default TopicsSkeleton;
