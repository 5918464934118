import React, { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  setImages,
  editorContent,
  setEditorContent,
  quillRef,
}) => {
  const handleImageUpload = (file) => {
    if (file) {
      setImages((prev) => [...prev, file]);
    }
  };

  // Memoize the modules configuration to prevent re-renders
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
        ],
        handlers: {
          image: () => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            // input.setAttribute("accept", "image/*");

            // Use a separate handler to maintain focus
            input.onchange = (e) => {
              const file = e.target.files?.[0];
              console.log(file);
              if (file) {
                handleImageUpload(file);
              }
            };

            input.click();
          },
        },
      },
    }),
    []
  ); // Empty dependency array since handlers don't depend on any props/state

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      modules={modules}
      formats={formats}
      defaultValue={editorContent}
      onChange={setEditorContent}
      placeholder="Write something here..."
      preserveWhitespace
    />
  );
};

export default TextEditor;
