import React, { useState } from "react";
import GoogleAuth from "./GoogleAuth";
import { useLogin } from "hooks/use-login";
import SignUp from "./SignUp";
import LoginModal from "./LoginModal";
import { GenerateVideoIcon } from "./Icons";
import { FaUser } from "react-icons/fa6";

const SignIn = ({ openDialog }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <div className="upload-icon m-0" onClick={openDialog}>
        <span>Sign In</span>
        <FaUser />
      </div>
    </div>
  );
};

export default SignIn;
