import styled, { keyframes } from "styled-components";

export const openModal = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background: rgba(0, 0, 0, 0.7);
  animation: ${openModal} 0.2s ease-in-out;

  .wrapper {
    width: 580px;
    border-radius: 4px;
    background: ${(props) => props.theme.lightNavyBlue};
    margin: 4rem auto;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4), 0px 0px 4px rgba(0, 0, 0, 0.25);
  }

  div.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid ${(props) => props.theme.thirdColor};
  }

  h3 {
    color: ${(props) => props.theme.primaryColor};
    font-size: 1.2rem;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media screen and (max-width: 600px) {
    .wrapper {
      width: 90%;
      margin: 4rem auto;
    }
  }

  @media screen and (max-width: 400px) {
    background: rgba(0, 0, 0, 0.9);
  }
`;

export default Wrapper;
