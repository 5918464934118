import React from "react";
import styled, { keyframes } from "styled-components";
import TopicsSkeleton from "./TopicsSkeleton";

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const CommunityWrapper = styled.div`
  display: flex;
  justify-content: between;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  flex: 2;
`;

const PostCard = styled.div`
  background: #1e293b;
  border-radius: 0.75rem;
  padding: 1rem;
`;

const SkeletonBase = styled.div`
  background: #334155;
  border-radius: 0.25rem;
  animation: ${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;

const PostHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

const Avatar = styled(SkeletonBase)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;

const Username = styled(SkeletonBase)`
  width: 5rem;
  height: 0.875rem;
  margin-left: 0.75rem;
  flex-grow: 1;
`;

const Content = styled.div`
  margin-bottom: 1rem;
`;

const TextLine = styled(SkeletonBase)`
  height: 0.875rem;
  margin-bottom: 0.5rem;

  &:nth-child(1) {
    width: 100%;
  }

  &:nth-child(2) {
    width: 90%;
  }

  &:nth-child(3) {
    width: 50%;
  }
`;

const Engagement = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const MetricContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const MetricSkeleton = styled(SkeletonBase)`
  width: 2rem;
  height: 0.875rem;
`;

const TimeStamp = styled(SkeletonBase)`
  width: 3rem;
  height: 0.75rem;
  margin-left: auto;
`;

const CommunitySkeleton = () => {
  const renderPost = () => (
    <PostCard>
      <PostHeader>
        <Avatar />
        <Username />
      </PostHeader>

      <Content>
        <TextLine />
        <TextLine />
        <TextLine />
      </Content>

      <Engagement>
        <MetricContainer>
          <MetricSkeleton />
          <span style={{ color: "#64748b" }}>Likes</span>
        </MetricContainer>
        <MetricContainer>
          <MetricSkeleton />
          <span style={{ color: "#64748b" }}>Comments</span>
        </MetricContainer>
        <TimeStamp />
      </Engagement>
    </PostCard>
  );

  return (
    <Container>
      {[1, 2, 3].map((index) => (
        <React.Fragment key={index}>{renderPost()}</React.Fragment>
      ))}
    </Container>
  );
};

export default CommunitySkeleton;
