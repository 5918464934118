import React from "react";
import { useQuery } from "react-query";
import { client } from "../utils/api-client";
import { useSnackbar } from "react-simple-snackbar";

const AuthContext = React.createContext(null);

export function AuthProvider({ children }) {
  const [openSnackbar] = useSnackbar();
  const token = localStorage.getItem("token");
  const authUser = localStorage.getItem("authUser");

  client.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("authUser");
        window.location.assign("/");
      }
      if (
        (error.response?.data?.error || error.response?.data?.message) &&
        openSnackbar
      ) {
        openSnackbar(
          error.response?.data?.error || error.response?.data?.message
        );
      }
      return Promise.reject(error);
    }
  );
  const { data } = useQuery(
    "AuthProvider",
    () => client.get("/auth/me").then((res) => res.data.user),
    { enabled: !!token && !!authUser }
  );
  let auth_user = null;
  if (token && authUser) {
    auth_user = JSON.parse(authUser);
  }
  const user = data || auth_user;

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}
export function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider component");
  }
  return context;
}
